<!--
 * @Author: yangliao
 * @Date: 2021-03-22 08:27:48
 * @LastEditTime: 2021-07-15 18:05:50
 * @LastEditors: yangliao
 * @Description: 缴费账单信息
 * @FilePath: /netHallOfficialAccounts/src/views/Invoice/invoiceBill.vue
-->
<template>
  <div class="bill">
    <div class="bill-money">
      <div class="bill-money-name">缴费金额</div>
      <div class="bill-money-nums">
        <span class="fhm-unit">¥</span>
        <span class="fhm-amount">{{ billData.clientPay.cciPayAmount }}</span>
      </div>
      <div class="bill-money-data">
        缴费时间：<span>{{ billData.clientPay.cciChargeTime }}</span>
      </div>
    </div>
    <div class="bill-news">
      <div class="bill-news-user">
        <div class="user-name">
          <div>{{ billData.clientPay.clientName }}「{{ billData.clientPay.meterCode }}-{{ billData.clientPay.clientAddr }}」</div>
        </div>
      </div>
      <div class="bill-news-title">
        <van-image width="24px" height="24px" fit="cover" :src="icMessage" />
        <span class="bill-news-title-name">账单信息</span>
      </div>
      <div
        class="bill-news-detail"
        v-for="(item, index) in billData.chargeMeterNetHallVOList"
        :key="index"
      >
        <div class="detail-name">
          <div>
            <span>销账金额：</span>
            <span>¥{{ item.cciFact }}</span>
          </div>
        </div>
        <div class="detail-button">
          <div v-if="item.chargeMonth">{{ item.chargeMonth | yearMonth }}开账</div>
          <div>
            <van-button
              class="bnr-button"
              size="mini"
              plain
              type="info"
              @click="handlerBill(item.cmId)"
            >
              账单详情
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <div class="bill-invoice">
      <div class="bill-invoice-title">
        <van-image width="24px" height="24px" fit="cover" :src="icTicket" />
        <span class="bill-invoice-title-name">电子发票</span>
      </div>
      <div class="bill-invoice-news">
        <div
          class="van-cell bill-invoice-detail"
          v-for="(item, index) in billData.invoiceInfoNetHallVOList"
          :key="index"
        >
          <div>
            <div>
              ¥<span>{{ item.invoiceAmount }}</span>
            </div>
            <div>{{ item.ticketIssuingMethod }}</div>
          </div>
          <div v-if="item.issueState === '开票中'">开票中...</div>
          <div v-if="item.pdfUrl">
            <a class="invoice-button" :href="item.pdfUrl" download>查看发票</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { Toast } from 'vant';
import { selectRecordsById, getElectronicInvoice } from '../../api/home';
import wx from 'weixin-js-sdk';
export default {
  name: 'InvoiceBill',
  data() {
    return {
      icMessage: require('../../assets/icon/ic_message.png'),
      icTicket: require('../../assets/icon/ic_ticket.png'),
      cciId: this.$route.query.cciId,
      billData: {
        clientPay: {
          cciAmount: '',
          cciChargeTime: '',
          clientName: '',
          meterCode: '',
          clientAddr: ''
        },
        chargeMeterNetHallVOList: [],
        invoiceInfoNetHallVOList: []
      }
    };
  },
  filters: {
    yearMonth: (val) => {
      if (val) return moment(val).format('YYYY年MM月');
      return val;
    }
  },
  mounted() {
    this.selectRecordsById();
  },
  methods: {
    /* 通过ID获取缴费详情 */
    async selectRecordsById() {
      if (this.cciId) {
        const { status, resultData } = await selectRecordsById(this.cciId);
        if (status === 'complete') {
          this.billData = { ...this.billData, ...resultData };
        }
      }
    },
    handlerBill(id) {
      this.$router.push({
        name: 'FeeDetails',
        path: '/QueryPay/FeeDetails',
        query: {
          name: 'invoice',
          wuserNo: this.billData.clientPay.meterCode,
          billId: id
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.bill {
  display: flex;
  flex-direction: column;

  .bill-money {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: url('../../assets/background/ic_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .bill-money-name {
      font-size: 16px;
      color: #ffffff;
    }

    .bill-money-nums {
      font-size: 38px;
      color: #ffffff;
      .fhm-unit {
        font-family: PingFangSC-Semibold;
        margin-right: 10px;
      }
      .fhm-amount {
        font-family: DINAlternate-Bold;
      }
    }

    .bill-money-data {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
    }
  }

  .bill-news {
    background: #fff;
    padding: 16px 16px 0 16px;

    .bill-news-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333333;

      .bill-news-title-name {
        margin-left: 8px;
      }
    }

    .bill-news-user {
      padding: 16px;
      margin-top: 8px;
      background: #f9fafb;
      border-radius: 8px;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: 14px;
      color: #4c5562;

      .user-name {
        display: flex;
        flex-wrap: wrap;

        > div {
          padding-bottom: 8px;
        }

        > div:first-child {
          margin-right: 8px;
        }
      }
    }

    .bill-news-detail {
      padding: 16px;
      margin-top: 8px;
      background: #f9fafb;
      border-radius: 8px;
      justify-content: space-between;
      margin-bottom: 16px;

      > div {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #4c5562;
      }

      .detail-name {
        font-family: PingFangSC-Medium;

        > div:first-child {
          display: flex;
          flex: 0.8;
        }
      }

      .detail-button {
        font-size: 12px;
        color: #8a96a4;
        align-items: center;

        .bnr-button {
          padding: 12px 10px;
          border-radius: 4px;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #4285f4;
        }
      }
    }
  }

  .bill-invoice {
    background: #fff;
    padding: 0 16px 16px 16px;

    .bill-invoice-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333333;

      .bill-invoice-title-name {
        margin-left: 8px;
      }
    }

    .bill-invoice-news {
      background: #f9fafb;
      border-radius: 8px;
      margin-top: 8px;

      .bill-invoice-detail {
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #8a96a4;

        > div:first-child {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #8a96a4;

          > div:first-child {
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #4c5562;
          }
        }

        .invoice-button {
          padding: 12px 10px;
          border-radius: 4px;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #4285f4;
          border: 1px solid #1989fa;
          border-radius: 4px;
          padding: 4px 8px;
        }
      }
    }
    /deep/.van-cell {
      background-color: transparent !important;
    }
    /deep/.van-cell::after {
      right: 0;
      left: 0;
    }
  }
}
</style>
